import styled from 'styled-components';
import spacing from 'styles/spacing';
import NextImage from "next/legacy/image";

export const Container = styled.div`
  margin: ${props =>
    props.direction === 'left'
      ? `${spacing.md} ${spacing.md} ${spacing.md} 0;`
      : `${spacing.md} 0 ${spacing.md} ${spacing.md}`};
  float: ${props => props.direction};
  width: ${props => props.width}%;
  position: relative;
`;

export const Image = styled(NextImage)`
  border-radius: 6px;
`;
