export default function EmojiNodeRenderer(props) {
  const { node, ...rest } = props;

  if (node.attrs.emoji) {
    return node.attrs.emoji;
  }
  if (node.attrs.fallbackImage) {
    return <img src={node.attrs.fallbackImage} />;
  }
  console.warn(`Cannot render emoji: ${JSON.stringify(node)}`);
  return null;
}

EmojiNodeRenderer.propTypes = {};
