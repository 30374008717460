import * as React from "react";
import { nameToComponentMap, getProps } from "./DefaultRichtext/utils";

export default function withMarks(Component) {
  return function WithMarksComponent(props) {
    const { node } = props;

    if (!node || !node.marks) {
      return <Component {...props} />;
    }
    return (
      node &&
      node.marks.reduce((previousValue, currentMark, currentIndex) => {
        const type = nameToComponentMap[currentMark.type];
        if (!type) {
          console.warn(`Unknown mark found ${currentMark.type}`);
          return previousValue;
        }

        // Component Props
        const markProps = {
          key: `withMarks-${currentIndex}`,
          ...getProps(currentMark),
        };

        return React.createElement(type, markProps, previousValue);
      }, React.createElement(Component, props))
    );
  };
}
