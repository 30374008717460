import React from "react";
import BasicContentPreview, {
  BasicContentPreviewProps,
} from "components/molecules/contentPreviewLayouts/BasicContentPreview";
import Link from "next/link";
import { storyblokLoader } from 'utils/storyblok';
import * as Styled from "./SideImageContentPreview.styled";

interface SideImageContentPreviewProps extends BasicContentPreviewProps {
  image?: string;
  imageAlt?: string;
  isAboveTheFold?: boolean;
}

export default function SideImageContentPreview(
  props: SideImageContentPreviewProps
) {
  const { image, imageAlt, fullSlug, isAboveTheFold = false } = props;

  return (
    <Styled.Wrapper>
      {image ? (
        <Link href={`/${fullSlug}`} passHref legacyBehavior>
          <Styled.LinkWrapper>
            <Styled.SideImage
              loader={storyblokLoader}
              src={image}
              alt={imageAlt}
              layout="fill"
              priority={isAboveTheFold}
            />
          </Styled.LinkWrapper>
        </Link>
      ) : null}
      <BasicContentPreview {...props} />
    </Styled.Wrapper>
  );
}
