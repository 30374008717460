import CodeHighlight, { CodeHighlightProps } from "./CodeHighlight";

export default function CodeHighlightStoryblokAdaptor(
  props: CodeHighlightProps
) {
  const { language, codeString, metastring } = props;

  return (
    <CodeHighlight
      language={language}
      codeString={codeString}
      metastring={metastring}
    />
  );
}
