import styled from "styled-components";
import {
  transformStoryblokRichtextLinks,
  isAtheminuteLink,
} from "../../../../utils/slugs";

const StyledText = styled.span`
  ${(props) =>
    props.customClass === "centered"
      ? `
            display: inline-flex;
            justify-content: center;
            text-align: center;
            width: 100%;
        `
      : ""}

  ${(props) =>
    props.customClass === "alignRight"
      ? `
            display: inline-flex;
            justify-content: flex-end;
            text-align: right;
            width: 100%;
        `
      : ""}
`;

export const nameToComponentMap = {
  bold: "strong",
  italic: "em",
  strike: "s",
  underline: "u",
  code: "code",
  blockquote: "blockquote",
  code_block: "pre",
  bullet_list: "ul",
  list_item: "li",
  horizontal_rule: "hr",
  image: "img",
  link: "a",
  hard_break: "br",
  styled: StyledText,
  anchor: "span",
  highlight: "mark",
  textStyle: "span",
  superscript: "sup",
  subscript: "sub",
};

export const getProps = (node) => {
  switch (node.type) {
    case "textStyle":
      return {
        style: {
          color: node.attrs.color,
        },
      };
    case "highlight":
      return {
        style: {
          backgroundColor: node.attrs.color,
        },
      };
    case "anchor":
      return {
        id: node.attrs.id,
      };
    case "image":
      return {
        alt: node.attrs.alt,
        src: node.attrs.src,
        title: node.attrs.title,
      };
    case "link":
      const isLocalLink = isAtheminuteLink(node.attrs.href);

      let href;
      if (node.attrs.linktype === "email") {
        href = `mailto:${node.attrs.href}`;
      } else {
        href = node.attrs.href;
        href = transformStoryblokRichtextLinks(href);
      }
      return {
        href,
        target: node.attrs.target,
        ...(isLocalLink
          ? {}
          : {
              rel: "noopener noreferrer",
            }),
      };
    case "styled":
      return {
        customClass: node.attrs.class,
      };
    default:
      return {};
  }
};
