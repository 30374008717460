import { CallToAction, LinkTypes } from "types/attheminute";
import {
  StoryblokAnchorLink,
  StoryblokCallToAction,
  StoryblokGeneralLink,
  StoryblokVideoLink,
} from "types/storyblok";
import { transformStoryblokInternalLinks } from "utils/slugs";

export function resolveStoryblokLink(
  storyblokLink: StoryblokVideoLink | StoryblokGeneralLink | StoryblokAnchorLink
): LinkTypes | undefined {
  switch (storyblokLink.component) {
    case "anchorLink":
      return {
        id: storyblokLink._uid,
        component: "anchorLink",
        targetAnchorId: storyblokLink.targetAnchorId,
      };
    case "generalLink":
      let url = storyblokLink.link?.cached_url;
      if (storyblokLink.link?.linktype === "story") {
        url = transformStoryblokInternalLinks(url);
      }

      return {
        id: storyblokLink._uid,
        component:
          storyblokLink.link?.linktype === "story"
            ? "internalLink"
            : "externalLink",
        newTab: storyblokLink.newTab,
        url,
      };
    case "videoLink":
      return {
        id: storyblokLink._uid,
        component: "videoLink",
        youtubeUrl: storyblokLink.youtubeUrl,
      };
    default:
      return undefined;
  }
}

export function resolveStoryblokCta(
  storyblokCTA: StoryblokCallToAction
): LinkTypes | undefined {
  if (!storyblokCTA.link || storyblokCTA.link.length !== 1) {
    return undefined;
  }
  const storyblokLink = storyblokCTA.link[0];
  return resolveStoryblokLink(storyblokLink);
}

export function resolveCallToActions(
  callToActions: StoryblokCallToAction[]
): CallToAction[] {
  return callToActions.map((storyblokCTA) => {
    const link = resolveStoryblokCta(storyblokCTA);

    return {
      id: storyblokCTA._uid,
      text: storyblokCTA.text,
      appearance: storyblokCTA.appearance,
      colourName: storyblokCTA.colourName,
      link,
    };
  });
}
