import styled from 'styled-components';
import screenSize from 'styles/mediaQueries';
import spacing from 'styles/spacing';
import { TextH3, TextP } from 'styles/typography';
import NextImage from "next/legacy/image";

const getGridItemStyles = props => {
  switch (props.imagePosition) {
    case 'left':
      return `
        grid-template-columns: ${props.gridItemColumns};
        grid-template-rows: 1fr;
      `;

    case 'right':
      return `
        grid-template-columns: ${props.gridItemColumns};
        grid-template-rows: 1fr;
      `;

    case 'bottom':
      return `
        grid-template-columns: ${props.gridItemColumns};
        grid-template-rows: auto 1fr;
      `;

    case 'top':
      return `
        grid-template-columns: ${props.gridItemColumns};
        grid-template-rows: auto 1fr;
      `;
    default:
      return `
        grid-template-columns: ${props.gridItemColumns};
        grid-template-rows: auto 1fr;
      `;
  }
};

export const GridPanel = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(${props => props.columns}, minmax(0, 1fr));
  width: 100%;
  grid-gap: ${spacing.sm};
  justify-items: center;

  ${screenSize.minTablet`
    grid-template-columns: repeat(${props => props.columns}, minmax(0, 1fr));
    grid-gap: ${spacing.lg} ${spacing.xl};
  `}
`;

export const GridItem = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  ${getGridItemStyles};
  text-align: left;
  grid-gap: ${spacing.xs};

  ${screenSize.minTablet`
    margin-bottom: 0;
  `}
`;

export const GridItemImageContainer = styled.div`
  position: relative;
`;

export const GridItemImage = styled(NextImage)`
  border-radius: 6px;

  ${screenSize.minDesktop`
    max-width: 21.875rem;
  `}
`;

export const GridItemText = styled.div`
  display: flex;
  align-items: center;
`;

export const GridItemHeading = styled(TextH3)`
  margin: 0;
  line-height: 1.57;
  text-align: left;
  margin-bottom: ${spacing.xxs};
  color: 'var(--color-dark)';
  font-weight: bold;
  font-size: 1rem;
  text-align: ${props => props.alignText};

  ${screenSize.minTablet`
    line-height: 1.38;
    font-size: 1.125rem;
    text-align: center;
  `}
`;

export const GridItemSubheading = styled(TextP)`
  line-height: 1.57;
  margin-top: 0;
  color: 'var(--color-darkAccent)';
  font-size: 0.8125rem;

  ${screenSize.minTablet`
    font-size: 0.875rem;
  `}
`;
