import { CallToAction } from "types/attheminute";
import ActionLink from "../ActionLink";
import CtaButton from "../CtaButton";

// This component is designed to be be used from the CMS.
export default function CallToAction(props: CallToAction) {
  return (
    <ActionLink
      key={props.id}
      dataTestId={`call-to-action-${props.id}`}
      link={props.link}
    >
      <CtaButton
        colourName={props.colourName}
        appearance={props.appearance}
        aria-label={props.text}
      >
        {props.text}
      </CtaButton>
    </ActionLink>
  );
}
