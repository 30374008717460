import styled from 'styled-components';
import Image from "next/legacy/image";
import spacing from 'styles/spacing';

export const Wrapper = styled.div`
  display: flex;
`;

export const SideImage = styled(Image)`
  object-fit: cover;
  margin: ${spacing.xxs};
  margin-right: 0;
  border-radius: 4px;
  flex: 1;
`;

export const LinkWrapper = styled.a`
  position: relative;
  text-decoration: none;
  flex-basis: 40%;
  display: flex;
`;
