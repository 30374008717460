import styled from "styled-components";
import Image from "next/legacy/image";
import spacing from "styles/spacing";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  flex-basis: 100%;
  margin-right: 0;
  border-radius: 4px;
  flex-grow: 1;
  margin: ${spacing.xxs};
`;

export const ImageOverlay = styled(Image)`
  object-fit: cover;
`;

export const ContentPreview = styled.div`
  background-color: var(--color-darkOverlay);
  margin: ${spacing.xxs};
  border-radius: 4px;
  z-index: 3;
  position: relative;
`;
