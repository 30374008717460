import styled from 'styled-components';
import spacing from 'styles/spacing';
import { xlFont } from 'styles/typography';

export const Aside = styled.aside`
  display: inline-block;
  clear: both;
  background: ${props =>
    props.type === 'warning'
      ? 'var(--color-darkAccentMuted)'
      : 'var(--color-lightAccentMuted)'};
  position: relative;
  padding: ${spacing.md} ${spacing.lg};
  margin: ${spacing.lg} 0;
  border-left: 3px solid
    ${props =>
      props.type === 'warning'
        ? 'var(--color-darkAccent)'
        : 'var(--color-darkAccent)'};
  border-radius: 6px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: var(--color-background);
  color: var(--color-darkAccent);
  font-size: ${xlFont};
`;
