import PropTypes from 'prop-types';
import {
  dimensionsFromStoryblokURL,
  maxWidthStoryblokImage,
  storyblokLoader,
} from "utils/storyblok";

import * as Styled from './ImageRenderer.styled';

export default function ImageRenderer(props) {
  const {
    node: {
      attrs: { alt, src, title },
    },
    ...rest
  } = props;

  if (!src) return null;

  const dimensions = dimensionsFromStoryblokURL(src);
  const imageSrc = maxWidthStoryblokImage(src, "1024");

  return (
    <Styled.Image
      {...rest}
      alt={alt}
      title={title}
      src={imageSrc}
      width={dimensions ? dimensions.width : 1}
      height={dimensions ? dimensions.height : 1}
      loader={storyblokLoader}
    />
  );
}

ImageRenderer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  node: PropTypes.object.isRequired,
};
