import PropTypes from "prop-types";
import {
  dimensionsFromStoryblokURL,
  maxWidthStoryblokImage,
  storyblokLoader,
} from "utils/storyblok";
import * as Styled from "./FloatingImage.styled";

export default function FloatingImage(props) {
  const { direction, image, altText, width } = props;
  const floatDirection = direction === "left" ? "left" : "right";
  const imageWidth = width || "50";

  if (!image) return null;

  const dimensions = dimensionsFromStoryblokURL(image);
  const src = maxWidthStoryblokImage(image, "600");

  return (
    <Styled.Container direction={floatDirection} width={imageWidth}>
      <Styled.Image
        alt={altText}
        title={altText}
        src={src}
        width={dimensions ? dimensions.width : 1}
        height={dimensions ? dimensions.height : 1}
        loader={storyblokLoader}
      />
    </Styled.Container>
  );
}

FloatingImage.propTypes = {
  direction: PropTypes.string,
  image: PropTypes.string,
  width: PropTypes.string,
  altText: PropTypes.string,
};
