import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mFont } from 'styles/typography';

const UL = styled.ul`
  > li {
    font-size: ${mFont};
  }
`;

export default function BulletListNodeRenderer(props) {
  const { children, ...rest } = props;
  return <UL {...rest}>{children}</UL>;
}

BulletListNodeRenderer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
