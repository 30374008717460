import { ArticlePageContent, PageConfig } from "types/attheminute";
import * as Styled from "./Articles.styled";
import BasicContentPreview from "components/molecules/contentPreviewLayouts/BasicContentPreview/BasicContentPreview";
import SideImageContentPreview from "components/molecules/contentPreviewLayouts/SideImageContentPreview";
import StackedImageContentPreview from "components/molecules/contentPreviewLayouts/StackedImageContentPreview";
import ImageOverlayContentPreview from "components/molecules/contentPreviewLayouts/ImageOverlayContentPreview";
import SmallContentPreview from "components/molecules/contentPreviewLayouts/SmallContentPreview";
import { mapArticleToContentPreviewProps } from "utils/articles";

export type ContentPreviewLayout =
  | "basic"
  | "sideImage"
  | "stackedImage"
  | "imageOverlay"
  | "small";

interface ArticlesProps {
  articles: PageConfig<ArticlePageContent>[]; // TODO: Update this to a minimal article type
  contentPreviewLayout: ContentPreviewLayout;
}

const ComponentMap = {
  basic: BasicContentPreview,
  sideImage: SideImageContentPreview,
  stackedImage: StackedImageContentPreview,
  imageOverlay: ImageOverlayContentPreview,
  small: SmallContentPreview,
};

export default function Articles(props: ArticlesProps) {
  const LayoutComponent = ComponentMap[props.contentPreviewLayout];

  return (
    <Styled.Wrapper>
      {props.articles.map((article) => {
        return (
          <LayoutComponent
            key={article.id}
            {...mapArticleToContentPreviewProps(article)}
            description={undefined} // TODO: This is for teaching homepage
            authorImage={undefined} // TODO: This is for teaching homepage
          />
        );
      })}
    </Styled.Wrapper>
  );
}
