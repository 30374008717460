import PropTypes from "prop-types";
import {
  dimensionsFromStoryblokURL,
  maxWidthStoryblokImage,
  storyblokLoader,
} from "utils/storyblok";
import * as Styled from "./ImageTextGrid.styled";

const defaultGridItems = [
  {
    heading: "Sapling",
    subheading: "hello",
    image: {
      filename: "//a.storyblok.com/f/82408/350x350/27dc236843/sapling.png",
    },
  },
  {
    heading: "Grass",
    subheading: "hello",
    image: {
      filename: "//a.storyblok.com/f/82408/350x350/5db22f2ca5/grass.png",
    },
  },
  {
    heading: "Flint",
    subheading: "hello",
    image: {
      filename: "//a.storyblok.com/f/82408/350x350/a31c5b5d38/flowers.png",
    },
  },
];

export default function ImageTextGrid(props) {
  const { gridItems, columns, imagePosition, gridItemColumns, alignText } =
    props;

  return (
    <Styled.GridPanel columns={columns}>
      {gridItems.map((gridItem, index) => {
        let dimensions;
        let src;
        const hasImage = gridItem?.image?.filename;

        if (hasImage) {
          dimensions = dimensionsFromStoryblokURL(gridItem.image.filename);
          src = maxWidthStoryblokImage(gridItem.image.filename, "1024");
        }

        return (
          <Styled.GridItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            dataTestId={`grid-item-${index}`}
            imagePosition={imagePosition}
            gridItemColumns={gridItemColumns}
          >
            <Styled.GridItemImageContainer>
              {hasImage ? (
                <Styled.GridItemImage
                  src={src}
                  alt={gridItem.altText || gridItem.heading}
                  title={gridItem.altText || gridItem.heading}
                  width={dimensions ? dimensions.width : 1}
                  height={dimensions ? dimensions.height : 1}
                  loader={storyblokLoader}
                />
              ) : null}
            </Styled.GridItemImageContainer>
            <Styled.GridItemText>
              <Styled.GridItemHeading alignText={alignText}>
                {gridItem.heading}
              </Styled.GridItemHeading>
              <Styled.GridItemSubheading>
                {gridItem.subheading}
              </Styled.GridItemSubheading>
            </Styled.GridItemText>
          </Styled.GridItem>
        );
      })}
    </Styled.GridPanel>
  );
}

ImageTextGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      image: PropTypes.object,
      subheading: PropTypes.string,
      altText: PropTypes.string,
    }).isRequired
  ).isRequired,
  columns: PropTypes.number,
};

ImageTextGrid.defaultProps = {
  gridItems: defaultGridItems,
  columns: 3,
  imagePosition: "top",
  gridItemColumns: "1fr",
  alignText: "center",
};
