import * as React from 'react';
import PropTypes from 'prop-types';
export default function TextNodeRenderer(props) {
  const { node } = props;

  return node.text;
}

TextNodeRenderer.propTypes = {
  node: PropTypes.object,
  index: PropTypes.string,
};
