import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.md};

  ${screenSize.minTablet`
    grid-template-columns: 1fr 1fr;
  `}
`;
