import { Category } from "types/attheminute";
import Link from "next/link";
import * as Styled from "./SmallContentPreview.styled";
import { storyblokLoader } from "utils/storyblok";

interface SmallContentPreviewProps {
  image?: string;
  imageAlt?: string;
  heading?: string;
  description?: string;
  authorName?: string;
  authorImage?: string;
  hideDescription?: boolean;
  categories?: Category[];
  fullSlug: string;
  style?: React.CSSProperties;
}

export default function SmallContentPreview(props: SmallContentPreviewProps) {
  const {
    heading,
    description,
    hideDescription = false,
    categories,
    image,
    imageAlt,
    fullSlug,
    style = {},
  } = props;

  // Just get the first category even if the are multiple.
  // Future improvement: Somehow show multiple category lines or something.
  const colourName =
    categories && categories.length && categories[0].colour
      ? categories[0].colour
      : "text";

  return (
    <Styled.Wrapper borderColourName={colourName} style={style}>
      <Link href={`/${fullSlug}`} passHref legacyBehavior>
        <Styled.ImageLinkWrapper>
          <Styled.PreviewImage
            loader={storyblokLoader}
            src={image || "images/placeholder.png"}
            alt={imageAlt}
            layout="fill"
          />
        </Styled.ImageLinkWrapper>
      </Link>
      <Styled.ContentPreview>
        <Link href={`/${fullSlug}`} passHref legacyBehavior>
          <Styled.LinkWrapper>
            <Styled.Title noMarginTop>{heading}</Styled.Title>
            <Styled.Description hide={hideDescription}>
              {description}
            </Styled.Description>
          </Styled.LinkWrapper>
        </Link>
      </Styled.ContentPreview>
    </Styled.Wrapper>
  );
}
