import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { sFont } from "styles/typography";
import spacing from "styles/spacing";

export const Span = styled.span`
  display: inline-block;
  width: ${spacing.lg};
  user-select: none;
  opacity: 0.3;
`;

export const Wrapper = styled.div`
  overflow: auto;
  margin-left: -${spacing.md};
  margin-right: -${spacing.md};

  ${screenSize.minDesktop`
    margin-left: -${spacing.xxl};
    margin-right: -${spacing.xxl};
  `}
`;

export const Pre = styled.pre`
  float: left;
  min-width: 100%;
  overflow: initial;

  font-size: ${sFont};
  line-height: 1.6;
  padding: ${spacing.md};
  border-radius: 4px;
`;

export const Line = styled.div<{ $shouldHighlight?: boolean }>`
  ${(props) =>
    props.$shouldHighlight
      ? "background: var(--color-lightAccentMuted);"
      : null}
`;
