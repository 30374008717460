import PropTypes from 'prop-types';
import StoryblokRichtext from 'components/ui/StoryblokRichtext';
import * as Icons from 'components/ui/Icons';
import * as Styled from './Aside.styled';

export default function Aside(props) {
  const { richContent, type, icon, children } = props;

  const IconComponent = icon && Icons[icon];
  return (
    <Styled.Aside type={type}>
      {IconComponent && (
        <Styled.IconWrapper type={type}>
          {IconComponent && <IconComponent fontSize="inherit" />}
        </Styled.IconWrapper>
      )}
      {richContent && richContent.content && (
        <StoryblokRichtext content={richContent.content} />
      )}
      {children}
    </Styled.Aside>
  );
}

Aside.defaultProps = {
  type: undefined,
  icon: undefined,
  richContent: undefined,
};

Aside.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  richContent: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
