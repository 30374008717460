import DefaultRichtext from './DefaultRichtext';

import ImageRenderer from './ImageRenderer';
import BlokRenderer from './BlokRenderer';

export const isRichTextEmpty = (root) => {
  if (!root || !root.content) return true;
  if (Array.isArray(root.content) && root.content.length === 1 && !root.content[0].content) return true;
  return false;
}

export default function StoryblokRichtext(props) {
  const customRenderers = {
    image: ImageRenderer,
    blok: BlokRenderer,
  };

  return <DefaultRichtext {...props} customRenderers={customRenderers} />;
}
