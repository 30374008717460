import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mFont } from 'styles/typography';

const OL = styled.ol`
  > li {
    font-size: ${mFont};
  }
`;

export default function OrderedListNodeRenderer(props) {
  const { node, children, ...rest } = props;
  const start = node.attrs && node.attrs.order ? node.attrs.order : 1;

  return (
    <OL start={start} {...rest}>
      {children}
    </OL>
  );
}

OrderedListNodeRenderer.propTypes = {
  node: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
