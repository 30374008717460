import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextNodeRenderer from './TextNodeRenderer';
import OrderedListNodeRenderer from './OrderedListNodeRenderer';
import BulletListNodeRenderer from './BulletListNodeRenderer';
import EmojiNodeRenderer from "./EmojiNodeRenderer";
import { nameToComponentMap, getProps } from './utils';
import withMarks from '../withMarks';

const defaultRenderers = {
  text: TextNodeRenderer,
  ordered_list: OrderedListNodeRenderer,
  bullet_list: BulletListNodeRenderer,
  emoji: EmojiNodeRenderer,
};

const getComponent = (renderers, node, index, rest) => {
  // Render children first
  const children =
    node.content &&
    node.content.map((childNode, childIndex) =>
      getComponent(renderers, childNode, `${index}-${childIndex}`, rest)
    );

  const CustomRenderer = renderers[node.type];
  if (CustomRenderer) {
    const customRendererProps = {
      index,
      key: index,
      node,
      children,
    };
    return withMarks(renderers[node.type])(customRendererProps);
  }

  // Component Type
  let componentType = null;
  switch (node.type) {
    case 'paragraph':
      componentType = !node.content ? 'br' : 'p';
      break;
    case 'heading':
      componentType = `h${node.attrs.level}`;
      break;
    default:
      componentType = nameToComponentMap[node.type];
      break;
  }

  // Component Props
  const props = {
    key: index,
    ...getProps(node),
    ...rest,
  };

  const reactComponent =
    componentType && React.createElement(withMarks(componentType), props, children);

  if (!reactComponent) {
    console.warn(`Unknown tag found ${node.type} ${JSON.stringify(node)}`);
    return null;
  }

  return reactComponent;
};

const Wrapper = styled.div`
  >:first-child {
    margin-top: 0;
  }
`;

export default function DefaultRichtext(props) {
  const { content, customRenderers, ...rest } = props;

  const renderers = {
    ...defaultRenderers,
    ...customRenderers,
  };

  return (
    <Wrapper>
      {content.map((node, index) =>
        getComponent(renderers, node, `${index}`, rest)
      )}
    </Wrapper>
  );
}

DefaultRichtext.defaultProps = {
  customRenderers: {},
};

DefaultRichtext.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customRenderers: PropTypes.object,
};
