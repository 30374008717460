import * as React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { getSectionWrapperProps } from "components/ui/SectionWrapper";

import SubscribeForm from "./SubscribeForm";
import * as Styled from "./EmailSubscribe.styled";

function EmailSubscribe(props) {
  const {
    text,
    ctaButton,
    sectionOptions,
    dataTestId,
    theme,
    source,
    defaultEmailList,
    campaignName,
    landingPageUrl,
    generateReferralCode,
    ctaText,
    referralSuccessText,
    align,
  } = props;

  const sectionWrapperProps =
    sectionOptions && sectionOptions.length && sectionOptions[0];

  const router = useRouter();

  return (
    <Styled.SectionWrapper
      data-testid={dataTestId}
      {...getSectionWrapperProps(sectionWrapperProps || props)}
    >
      <SubscribeForm
        align={align}
        source={`${router.asPath}-${source || "email-subscribe-section"}`}
        defaultEmailList={defaultEmailList}
        theme={theme}
        text={text}
        ctaButtonAppearance={ctaButton}
        campaignName={campaignName}
        landingPageUrl={landingPageUrl}
        generateReferralCode={generateReferralCode}
        ctaText={ctaText}
        referralSuccessText={referralSuccessText}
      />
    </Styled.SectionWrapper>
  );
}

EmailSubscribe.propTypes = {
  text: PropTypes.string,
  dataTestId: PropTypes.string,
};

EmailSubscribe.defaultProps = {
  text: "",
  dataTestId: "email-subscribe",
  ctaButton: {
    colourName: "mainBrandColour",
    appearance: "solid",
    text: "Subscribe",
  },
};

export default EmailSubscribe;
