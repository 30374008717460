// Super basic implementation to check if the URL is from attheminute or not.
export const isAtheminuteLink = (href: string) => {
  if (href.startsWith("/")) return true;

  if (href.startsWith("http")) {
    try {
      const url = new URL(href);
      return (
        url.hostname === "www.atheminute.com" ||
        url.hostname === "atheminute.com"
      );
    } catch (err) {
      return false;
    }
  }

  // Default to false
  return false;
};

// These are here because the structure of storyblok does not match our url structure
let transformations = [
  // General pages
  {
    regex: /^\/?website-pages\//,
    replacement: "/",
  },
  // Teaching resources
  {
    regex: /^\/?subject\//,
    replacement: "/teaching/subject/",
  },
  {
    regex: /^\/?teaching-resource\//,
    replacement: "/teaching/product/",
  },
  // Guides
  {
    regex: /^\/?article\/guide\//,
    replacement: "/teaching/guide/",
  },
  {
    regex: /^\/?category\/guide\//,
    replacement: "/teaching/guide/category/",
  },
  // References
  {
    regex: /^\/?article\/reference\//,
    replacement: "/teaching/reference/",
  },
  {
    regex: /^\/?category\/reference\//,
    replacement: "/teaching/reference/category/",
  },
  // Blog
  {
    regex: /^\/?article\/blog\//,
    replacement: "/article/",
  },
  {
    regex: /^\/?category\/blog\//,
    replacement: "/blog/category/",
  },
];

export const transformStoryblokRichtextLinks = (url: string) => {
  for (let t of transformations) {
    if (t.regex.test(url)) {
      return url.replace(t.regex, t.replacement);
    }
  }
  return url;
};

export const transformStoryblokInternalLinks = (url?: string) => {
  if (!url) return url;

  for (let t of transformations) {
    if (t.regex.test(url)) {
      return url.replace(t.regex, t.replacement);
    }
  }
  return url;
}