export default function HTMLEmbed(props) {
  const { html } = props;

  const markup = {
    __html: html
  };

  return (
    <div dangerouslySetInnerHTML={markup} />
  );
}
