import * as React from "react";
import { Highlight, themes } from "prism-react-renderer";

import * as Styled from "./CodeHighlight.styled";

const RE = /{([\d,-]+)}/;

function calculateLinesToHighlight(meta: string) {
  const match = RE.exec(meta);
  if (match) {
    const lineNumbers = match[1]
      .split(",")
      .map((v) => v.split("-").map((y) => parseInt(y, 10)));
    return (index: number) => {
      const lineNumber = index + 1;
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start
      );
      return inRange;
    };
  }
  return () => false;
}

export interface CodeHighlightProps {
  language: string;
  codeString: string;
  metastring: string;
}

function CodeHighlight({
  codeString,
  language,
  metastring,
}: CodeHighlightProps) {
  const shouldHighlightLine = calculateLinesToHighlight(metastring);
  return (
    <Highlight code={codeString} language={language} theme={themes.oceanicNext}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Styled.Wrapper>
          <Styled.Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <Styled.Line
                key={i}
                {...getLineProps({
                  line,
                  key: i,
                })}
                $shouldHighlight={shouldHighlightLine(i)}
              >
                <Styled.Span>{i + 1}</Styled.Span>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </Styled.Line>
            ))}
          </Styled.Pre>
        </Styled.Wrapper>
      )}
    </Highlight>
  );
}

export default CodeHighlight;
