import React from "react";
import BasicContentPreivew, { BasicContentPreviewProps } from "components/molecules/contentPreviewLayouts/BasicContentPreview";
import { storyblokLoader } from 'utils/storyblok';

import * as Styled from "./ImageOverlayContentPreview.styled";

interface ImageOverlayContentPreview extends BasicContentPreviewProps {
  image?: string;
  imageAlt?: string;
  isAboveTheFold?: boolean;
}

export default function ImageOverlayContentPreview(props: ImageOverlayContentPreview) {
  const { image, imageAlt, isAboveTheFold = false, } = props;

  return (
    <Styled.Wrapper>
      {image ? (
        <Styled.ImageWrapper>
          <Styled.ImageOverlay
            loader={storyblokLoader}
            src={image}
            alt={imageAlt}
            layout="fill"
            priority={isAboveTheFold}
          />
        </Styled.ImageWrapper>
      ) : null}
      <Styled.ContentPreview>
        <BasicContentPreivew
          {...props}
          theme="dark" // ?
        />
      </Styled.ContentPreview>
    </Styled.Wrapper>
  );
}
