import styled from "styled-components";
import spacing from "styles/spacing";
import { TextH3, textLabelStyles, mFont, sFont } from "styles/typography";
import { clampLineStyles } from "styles/snippets";
import screenSize from "styles/mediaQueries";
import NextImage from "next/legacy/image";

interface WrapperProps {
  borderColourName: string; // TODO: colour names
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: 1;
  border-left: solid 4px var(--color-${(props) => props.borderColourName});
  min-width: 0;
`;

export const ContentPreview = styled.div`
  flex: 1;
  min-width: 0;
  padding: ${spacing.xs};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${screenSize.minDesktop`
    padding: ${spacing.md};
  `}
`;

export const ImageLinkWrapper = styled.a`
  position: relative;
  text-decoration: none;
  flex-basis: 25%;
  display: flex;

  margin: ${spacing.xs} 0 ${spacing.xs} ${spacing.xxs};

  ${screenSize.minDesktop`
    margin: ${spacing.md} 0 ${spacing.md} ${spacing.xs};
  `}
`;

export const PreviewImage = styled(NextImage)`
  object-fit: cover;
  border-radius: 4px;
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
  color: var(--color-text);

  :hover {
    color: var(--color-darkAccent);
  }
`;

export const Title = styled(TextH3)`
  color: inherit;
  font-size: ${sFont};
  line-height: 1.6;
  margin-bottom: ${spacing.xs};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface DescriptionProps {
  hide: boolean;
}

export const Description = styled.div<DescriptionProps>`
  ${textLabelStyles}
  color: inherit;
  ${(props) => (props.hide ? "display: none;" : "")}
  margin-bottom: 0;

  ${screenSize.minTablet`
    ${(props: DescriptionProps) => (props.hide ? "display: none;" : "display: block;")}
  `}
  ${clampLineStyles(2)}
`;
