import { PageConfig, ArticlePageContent } from "types/attheminute";
import { maxWidthStoryblokImage } from "./storyblok";

export const mapArticleToContentPreviewProps = (article: PageConfig<ArticlePageContent>) => ({
  blogSeries: article.content?.blogSeries,
  authorImage: maxWidthStoryblokImage(article.content?.author?.profilePicture, '60'),
  authorName: article.content?.author?.name,
  categories: article.content?.categories,
  description: article.content?.shortDescription,
  firstPublishedAt: article.firstPublishedAt,
  fullSlug: article.fullSlug,
  heading: article.content?.heading,
  image: maxWidthStoryblokImage(article.content?.previewImage, '800'),
  imageAlt: article.content?.previewImageAlt,
});