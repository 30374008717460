import EmailSubscribe from "components/sections/EmailSubscribe";
import Articles from "components/ui/Articles";
import Aside from "components/ui/Aside";
import CallToAction from "components/ui/CallToAction";
import CodeHighlight from "components/ui/CodeHighlight/CodeHighlightStoryblokAdaptor";
import FloatingImage from "components/ui/FloatingImage";
import HTMLEmbed from "components/ui/HTMLEmbed";
import ImageTextGrid from "components/ui/ImageTextGrid";
import PropTypes from "prop-types";
import { resolveCallToActions } from "ssg/storyblok-adaptors/callToAction";

const componentMap = {
  aside: { component: Aside },
  articles: { component: Articles },
  blogSubscribe: { component: EmailSubscribe },
  floatingImage: { component: FloatingImage },
  imageTextGrid: { component: ImageTextGrid },
  codeHighlight: { component: CodeHighlight },
  htmlEmbed: { component: HTMLEmbed },
  ctaButton: {
    component: CallToAction,
    resolver: (blok) => resolveCallToActions([blok])[0],
  },
};

const defaultResolver = (blok) => blok;

export default function BlokRenderer(props) {
  const { node } = props;

  return node.attrs.body.map((blok) => {
    const config = componentMap[blok.component];
    if (!config) return <span>Component not found </span>;

    const Component = config.component;
    const props = config.resolver
      ? config.resolver(blok)
      : defaultResolver(blok);

    return <Component key={blok._uid} {...props} />;
  });
}

BlokRenderer.propTypes = {
  node: PropTypes.object,
};
